import { lazy } from 'react'

const TemplateTitle = '%s - InksVilla '
const DefaultRoute = '/'

const Routes = [
  {
    path: '/home',
    component: lazy(() => import('../../views/Home')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/admins',
    component: lazy(() => import('../../views/admin/admins')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/support',
    component: lazy(() => import('../../views/admin/support')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/purchase',
    component: lazy(() => import('../../views/admin/purchase')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/terms',
    component: lazy(() => import('../../views/admin/terms')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/privacy',
    component: lazy(() => import('../../views/admin/privacy')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/notification',
    component: lazy(() => import('../../views/admin/notification')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/newsfeed',
    component: lazy(() => import('../../views/admin/reportedfeed')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/artist',
    component: lazy(() => import('../../views/admin/artist')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/client',
    component: lazy(() => import('../../views/admin/client')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/category',
    component: lazy(() => import('../../views/admin/category')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/designs/verifiedDesigns',
    component: lazy(() => import('../../views/admin/designs/verifiedDesigns')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/designs/unverifiedDesigns',
    component: lazy(() =>
      import('../../views/admin/designs/unverifiedDesigns')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/tattoopaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/TattooPaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/tattoounpaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/TattooUnPaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/artistpaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/ArtistAppointmentPaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/artistunpaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/ArtistAppointmentUnPaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/purchasepaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/PurchasePaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/transaction/purchaseunpaidlist',
    component: lazy(() =>
      import('../../views/admin/transaction/PurchaseUnPaidManage.js')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/banner',
    component: lazy(() => import('../../views/admin/banner')),
    layout: 'NefscunUserLayout'
  },
  // course
  {
    path: '/admin/course',
    component: lazy(() => import('../../views/admin/course/CoursePage.jsx')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/view-course/:id',
    component: lazy(() => import('../../views/admin/course/CourseDetail.jsx')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/edit-course/:id',
    component: lazy(() => import('../../views/admin/course/EditCourse.jsx')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-course',
    component: lazy(() => import('../../views/admin/course/CreateCourse.jsx')),
    layout: 'NefscunUserLayout'
  },
  // lessons
  {
    path: '/admin/lessons/:courseId',
    component: lazy(() =>
      import('../../views/admin/Lessons/LessonsOfCourse.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-lesson/:courseId',
    component: lazy(() => import('../../views/admin/Lessons/CreateLesson.jsx')),
    layout: 'NefscunUserLayout'
  },
  // daily care
  {
    path: '/admin/dailycare',
    component: lazy(() =>
      import('../../views/admin/dailycare/DailyCareManage.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/edit-dailycare/:dailyCareId',
    component: lazy(() =>
      import('../../views/admin/dailycare/EditDailyCare.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-dailycare',
    component: lazy(() =>
      import('../../views/admin/dailycare/CreateDailyCare.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/daily-care-activities/:dailyCareId',
    component: lazy(() =>
      import('../../views/admin/dailycare/DailyCareActivities.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  // pdf
  {
    path: '/admin/pdf',
    component: lazy(() => import('../../views/admin/pdf/PdfManage.jsx')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-pdf',
    component: lazy(() => import('../../views/admin/pdf/CreatePdf.jsx')),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/edit-pdf/:id',
    component: lazy(() => import('../../views/admin/pdf/EditPdf.jsx')),
    layout: 'NefscunUserLayout'
  },
  // marketplace-category
  {
    path: '/admin/marketplace-category',
    component: lazy(() =>
      import('../../views/admin/marketplace-category/ManageCategory.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/edit-marketplace-category/:id',
    component: lazy(() =>
      import('../../views/admin/marketplace-category/EditCategory.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-marketplace-category',
    component: lazy(() =>
      import('../../views/admin/marketplace-category/CreateCategory.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  // marketplace-product
  {
    path: '/admin/marketplace-product',
    component: lazy(() =>
      import('../../views/admin/marketplace-product/ManageProduct.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/edit-marketplace-product/:id',
    component: lazy(() =>
      import('../../views/admin/marketplace-product/EditProduct.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/view-marketplace-product/:id',
    component: lazy(() =>
      import('../../views/admin/marketplace-product/EditProduct.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/create-marketplace-product',
    component: lazy(() =>
      import('../../views/admin/marketplace-product/CreateProduct.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  // order
  {
    path: '/admin/marketplace-order',
    component: lazy(() =>
      import('../../views/admin/marketplace-order/ManageOrder.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  {
    path: '/admin/view-marketplace-order/:id',
    component: lazy(() =>
      import('../../views/admin/marketplace-order/OrderDetail.jsx')
    ),
    layout: 'NefscunUserLayout'
  },
  // Users
  {
    path: '/admin/users',
    component: lazy(() => import('../../views/admin/users/usersManage.jsx')),
    layout: 'NefscunUserLayout'
  },
  // {
  //   path: '/admin/edit-user/:id',
  //   component: lazy(() => import('../../views/admin/users/Editusers.jsx')),
  //   layout: 'NefscunUserLayout'
  // },
  // {
  //   path: '/admin/view-user/:id',
  //   component: lazy(() => import('../../views/admin/users/usersDetail.jsx')),
  //   layout: 'NefscunUserLayout'
  // },
  // {
  //   path: '/admin/create-user',
  //   component: lazy(() => import('../../views/admin/users/addUsers.jsx')),
  //   layout: 'NefscunUserLayout'
  // },

  //Blank layout
  {
    path: '/homepage',
    className: 'nefscun-mis',
    component: lazy(() => import('../../views/Homepage')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/admin/logout',
    component: lazy(() => import('../../views/admin/Login')),
    layout: 'BlankLayout'
  },
  {
    path: '/admin/login',
    component: lazy(() => import('../../views/admin/Login')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/error',
    component: lazy(() => import('../../views/Error')),
    layout: 'BlankLayout'
  }
]

export { DefaultRoute, TemplateTitle, Routes }
