import config from '../configs/config'

const generateAPIRoute = (pathname, nestApp = false) => {
  const newPathName = pathname.startsWith('/') ? pathname.slice(1) : pathname
  if (!nestApp) {
    return config.baseUrl + newPathName
  }
  return config.v1baseUrl + newPathName
}

// type => courseCover, lessonVideo, userAvatar
const generateAssetsUrl = (assetPath, type) => {
  if (type === 'courseCover') {
    return generateAPIRoute(assetPath, true)
  } else if (type === 'daily-care-activity') {
    return generateAPIRoute(`/public/daily-care/activity/${assetPath}`, true)
  } else if (type === 'product') {
    return generateAPIRoute(`/assets/public/products/${assetPath}`, true)
  } else {
    return generateAPIRoute(assetPath, true)
  }
}

export { generateAssetsUrl }

export default generateAPIRoute
