import React from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap'

// core components
import ExamplesNavbar from '../../components/Navbars/ExamplesNavbar.js'
import LandingPageHeader from '../../components/Headers/LandingPageHeader.js'
import DemoFooter from '../../components/Footers/DemoFooter.js'
import generateAPIRoute from '../../../../api/api-route.js'

function LandingPage() {
  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('profile-page')
    return function cleanup() {
      document.body.classList.remove('profile-page')
    }
  })
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div id="aboutinksvilla" className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">About Inksvilla</h2>
                <h5 className="description">
                  Established in 2013 A.D, Tattoo House Nepal is a Kathmandu
                  based tattoo shop, providing the expertise in tattoos and
                  piercing of every kind. Coming from an art background, Sandip
                  Lama always had a deep passion for tattoo and decided to
                  pursue his passion as a career. Without sparing any time,
                  Sandip dedicated his time and effort founding Tattoo House
                  Nepal, and here we are now one of the best tattoo houses in
                  the town. Tattoo House Nepal is a diverse tattoo parlor
                  comprising skilled artists who are experts of tattoos, from
                  modern to traditional design layouts and tattooing techniques.
                  Blessed with such a talented and passionate, Tattoo House
                  Nepal keeps on creating new designs, evolving their style and
                  constantly pushing ourselves to make a masterpiece. It is our
                  ultimate hard work and passion that grew and strengthened us
                  to be one of the best.
                </h5>
                <br />

                <h2> Inksvilla App </h2>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="2">
                <div className="info">
                  <div className="icon">
                    <i className="fa fa-download" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Download App</h4>
                    <p className="description">
                      First of all download the app from the store either IOS or
                      Android.
                    </p>
                    {/* <Button className="btn-link" color="info" href="#">
                      Download
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon">
                    <i className="fa fa-user" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Register</h4>
                    <p>Register as a client or an artist.</p>
                    {/* <Button className="btn-link" color="info" href="#">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon">
                    <i className="fa fa-check" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Opt for a tattoo</h4>
                    <p>Choose from a variety of many tattoo designs.</p>
                    {/* <Button className="btn-link" color="info" href="#">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon">
                    <i className="fa fa-credit-card" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Payment</h4>
                    <p>Pay according to your convenience.</p>
                    {/* <Button className="btn-link" color="info" href="#">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon">
                    <i className="fa fa-sign-in" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Go for an appointment</h4>
                    <p>Pay according to your convenience.</p>
                    {/* <Button className="btn-link" color="info" href="#">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Artist Reviews</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require('../../assets/img/faces/clem-onojeghuo-3.jpg')
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Arthur</CardTitle>
                        <h6 className="card-category">Company Name</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      One of the best app that I have ever used for tattoo.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require('../../assets/img/faces/joe-gardner-2.jpg')
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Jockson</CardTitle>
                        <h6 className="card-category">Company Name</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                     It's easy to find near by tattoo artist with high quality work in Inksvilla.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require('../../assets/img/faces/erik-lucatero-2.jpg')
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Michael</CardTitle>
                        <h6 className="card-category">Company Name</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                  I got the best tattoo designs and near by artist in Inksvilla.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="contactus" className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="warning" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  )
}

export default LandingPage
