import React from "react";
import { Button, Container } from "reactstrap";
import BackgroundVideo from "../../../../assets/video/sample.mp4";

function LandingPageHeader() {
  const pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        const windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =`translate3d(0,${windowScrollTop}px,0)`;
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const VideoBackground = {
    position: "absolute",
    width: "auto",
    height: "auto",
    minWidth: "100%",
    minHeight: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"};
  const VideoBackgroundContainer = {
    position: "relative",
    width: "100vw",
    height: "60vh",
    overflow: "hidden"};

  return (
    <>
      {/* <div
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      > */}
      <div className="page-header" style={VideoBackgroundContainer} data-parallax={true}
        ref={pageHeader}>
          <video
            autoPlay
            loop
            muted
            style={VideoBackground}
            src={BackgroundVideo}
            type="video/mp4"
          />
        {/* </div> */}
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1 style={{color:"rgb(113 113 88)"}}>Inksvilla Tattoo</h1>
            <h3 style={{color:"rgb(113 113 88)"}}>A platform to connect the tattoo artist and enthusiast under the same hood.</h3>
            <br />
            {/* <img src={AppStoreLogo} alt="App Store" style={{height:"5vh", marginLeft:"-40px", width:"20vw"}}/>
            <br /> <br /> */}
            {/* <Button style={{borderColor:"rgb(113 113 88)", color:"rgb(113 113 88)"}}
              href="#"
              className="btn-round mr-2"
              color="neutral"
              target="_blank"
              outline
            >
              <i className="fa fa-apple" /> Download Now IOS
            </Button> */}
            <Button style={{borderColor:"rgb(113 113 88)", color:"rgb(113 113 88)"}}
            href="https://play.google.com/store/apps/details?id=com.inksvilla.tattoohouse"
            className="btn-round" color="neutral" type="button" outline target="_blank">
              <i className="fa fa-play"></i> Download Now Android
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
