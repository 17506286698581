// ** React Imports
import { useEffect, useState } from 'react'

// ** Custom Hooks
import { useRTL } from '@hooks/useRTL'

import { useSkin } from '@hooks/useSkin'

const BlankLayout = ({ children }) => {
  // ** Hooks
  useSkin()
  useRTL()

  // ** States
  const [isMounted, setIsMounted] = useState(false)

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <div className='blank-page'>
      <div className='app-content content'>
        <div className='content-wrapper'>
          <div className='content-body'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default BlankLayout

// // ** React Imports
// import { useState, useEffect } from 'react'
// import { useLocation } from 'react-router-dom'

// // ** Store & Actions
// import { useSelector, useDispatch } from 'react-redux'
// import { handleMenuCollapsed, handleContentWidth, handleMenuHidden } from '@store/actions/layout'

// // ** Third Party Components
// import classnames from 'classnames'
// import { ArrowUp } from 'react-feather'
// import ScrollToTop from 'react-scroll-up'
// import { Navbar, Button } from 'reactstrap'

// // ** Configs
// import themeConfig from '@configs/themeConfig'

// // ** Custom Components
// import Customizer from '@components/customizer'
// import FooterComponent from './components/footer'
// import NavbarComponent from './components/navbar'
// import SidebarComponent from './components/menu/vertical-menu'

// // ** Custom Hooks
// import { useRTL } from '@hooks/useRTL'
// import { useSkin } from '@hooks/useSkin'
// import { useNavbarType } from '@hooks/useNavbarType'
// import { useFooterType } from '@hooks/useFooterType'
// import { useNavbarColor } from '@hooks/useNavbarColor'

// // ** Styles
// import '@styles/base/core/menu/menu-types/vertical-menu.scss'
// import '@styles/base/core/menu/menu-types/vertical-overlay-menu.scss'

// const BlankLayout = props => {
//   // ** Props
//   const { children, navbar, footer, menu, routerProps, currentActiveItem } = props

//   // ** Hooks
//   const [skin, setSkin] = useSkin()
//   const [isRtl, setIsRtl] = useRTL()
//   const [navbarType, setNavbarType] = useNavbarType()
//   const [footerType, setFooterType] = useFooterType()
//   const [navbarColor, setNavbarColor] = useNavbarColor()

//   // ** States
//   const [isMounted, setIsMounted] = useState(false)
//   const [menuVisibility, setMenuVisibility] = useState(false)
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth)

//   // ** Store Vars
//   const dispatch = useDispatch()
//   const layoutStore = useSelector(state => state.layout)

//   // ** Update Window Width
//   const handleWindowWidth = () => {
//     setWindowWidth(window.innerWidth)
//   }

//   // ** Vars
//   const location = useLocation()
//   const contentWidth = layoutStore.contentWidth
//   const menuCollapsed = layoutStore.menuCollapsed
//   const isHidden = layoutStore.menuHidden

//   // ** Toggles Menu Collapsed
//   const setMenuCollapsed = val => dispatch(handleMenuCollapsed(val))

//   // ** Handles Content Width
//   const setContentWidth = val => dispatch(handleContentWidth(val))

//   // ** Handles Content Width
//   const setIsHidden = val => dispatch(handleMenuHidden(val))

//   //** This function will detect the Route Change and will hide the menu on menu item click
//   useEffect(() => {
//     if (menuVisibility && windowWidth < 1200) {
//       setMenuVisibility(false)
//     }
//   }, [location])

//   //** Sets Window Size & Layout Props
//   useEffect(() => {
//     if (window !== undefined) {
//       window.addEventListener('resize', handleWindowWidth)
//     }
//   }, [windowWidth])

//   //** ComponentDidMount
//   useEffect(() => {
//     setIsMounted(true)
//     return () => setIsMounted(false)
//   }, [])

//   // ** Vars
//   const footerClasses = {
//     static: 'footer-static',
//     sticky: 'footer-fixed',
//     hidden: 'footer-hidden'
//   }

//   const navbarWrapperClasses = {
//     floating: 'navbar-floating',
//     sticky: 'navbar-sticky',
//     static: 'navbar-static',
//     hidden: 'navbar-hidden'
//   }

//   const navbarClasses = {
//     floating: 'floating-nav',
//     sticky: 'fixed-top',
//     static: 'navbar-static-top',
//     hidden: 'd-none'
//   }

//   const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

//   if (!isMounted) {
//     return null
//   }
//   return (
//     <div>
    
//       {children}

//       {/* Vertical Nav Menu Overlay */}
//       <div
//         className={classnames('sidenav-overlay', {
//           show: menuVisibility
//         })}
//         onClick={() => setMenuVisibility(false)}
//       ></div>
//       {/* Vertical Nav Menu Overlay */}

    
//       <footer
//         className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
//           'd-none': footerType === 'hidden'
//         })}
//       >
//         {footer ? footer : <FooterComponent footerType={footerType} footerClasses={footerClasses} />}
//       </footer>

    
//     </div>
//   )
// }

// export default BlankLayout
