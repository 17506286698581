import React, { Component } from "react";

// styles
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/inksvilla-front.scss?v=1.3.0";
import "./assets/demo/demo.css?v=1.3.0";
// pages
import Index from "./views/Index.js";
import NucleoIcons from "./views/NucleoIcons.js";
import LandingPage from "./views/examples/LandingPage.js";
import ProfilePage from "./views/examples/ProfilePage.js";
import RegisterPage from "./views/examples/RegisterPage.js";

import { useRTL } from '@hooks/useRTL'

import { useSkin } from '@hooks/useSkin'


const IndexPage = () => {
  useSkin()
  useRTL()

  return (
        <LandingPage />
  )
}


export default IndexPage;

